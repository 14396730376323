import { reactive } from 'vue'
import { UsersRealisation } from '@/core/realisations/Users.realisation'
import { StaffUserT } from '@/core/types/Users.types'

export const useUsers = () => {
    const staffUsers = reactive({
        state: {
            is_loading: false,
            is_failed: false,
        },
        pagination: {
            current_page: 1,
            total_pages: 1,
        },
        data: [] as StaffUserT[],
    })

    const usersRealisation = new UsersRealisation()

    const loadStaffUsers = async () => {
        try {
            staffUsers.state.is_loading = true
            staffUsers.pagination.current_page = 1
            staffUsers.pagination.total_pages = 1

            const users = await usersRealisation.getStaffUsers(staffUsers.pagination.current_page)

            staffUsers.data = users.data
            staffUsers.pagination.total_pages = users.meta.last_page
            staffUsers.state.is_failed = false
        } catch (error) {
            console.error(error)
            staffUsers.state.is_failed = true
        } finally {
            staffUsers.state.is_loading = false
        }
    }

    const loadMoreStaffUsers = async () => {
        staffUsers.pagination.current_page++

        try {
            staffUsers.state.is_loading = true

            const users = await usersRealisation.getStaffUsers(staffUsers.pagination.current_page)
            staffUsers.data = staffUsers.data.concat(users.data)
            staffUsers.pagination.total_pages = users.meta.last_page
            staffUsers.state.is_failed = false
        } catch (error) {
            console.error(error)
            staffUsers.state.is_failed = true
        } finally {
            staffUsers.state.is_loading = false
        }
    }

    const createUser = async (user: StaffUserT): Promise<void> => {
        const response = await usersRealisation.createStaffUser(user)
        if (!response || !(response.code === 200 || response.code === 201)) return
        staffUsers.data.unshift(response.data)
    }

    const updateUser = async (user_id: number, user: StaffUserT): Promise<void> => {
        const response = await usersRealisation.updateStaffUser(user_id, user)
        if (!response || !(response.code === 200 || response.code === 201)) return

        const findedIndex = staffUsers.data.findIndex(
            (user: StaffUserT) => user.id === response.data.id
        )

        if (findedIndex < 0) return

        staffUsers.data[findedIndex] = response.data
    }

    const deleteUser = async (user_id: number): Promise<void> => {
        const findedIndex = staffUsers.data.findIndex((user: StaffUserT) => user.id === user_id)
        staffUsers.data.splice(findedIndex, 1)
        await usersRealisation.deleteUser(user_id)
    }

    return { staffUsers, loadStaffUsers, loadMoreStaffUsers, createUser, updateUser, deleteUser }
}
