
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        links: {
            type: Array,
            default: () => [],
        },
    },
})
