
import { defineComponent } from 'vue'

import SBreadCrumbs from '@/common/components/SBreadCrumbs/index.vue'

import iArrowLeft from '@/assets/icons/ArrowLeft.svg'

export default defineComponent({
    components: {
        's-bread-crumbs': SBreadCrumbs,
        'icon-arrow-left': iArrowLeft,
    },
    props: {
        orderId: {
            type: String,
            default: null,
        },
    },
    setup() {
        const breadcrumbsLinks = [
            {
                id: 1,
                name: 'Профиль',
                path: '/profile',
            },
            {
                id: 2,
                name: 'Пользователи',
                path: '/profile/users',
            },
        ]

        return { breadcrumbsLinks }
    },
})
