
import { defineComponent, onMounted, Ref, ref } from 'vue'

import UsersHeader from '@/modules/Users/components/UsersHeader/index.vue'
import UsersList from '@/modules/Users/components/UsersList/index.vue'

import UserForm from '@/modules/Users/components/UserForm/index.vue'

import SModal from '@/common/components/SModal/index.vue'

import { useModal } from '@/common/composable/useModal'
import { useUsers } from '@/modules/Users/composable/useUsers'

import { StaffUserT } from '@/core/types/Users.types'

import useNotifications from '@/common/composable/useNotifications'
const NOTIFICATIONS_GROUP = 'create-module'

import { useTitle } from 'vue-composable'

export default defineComponent({
    components: {
        'users-header': UsersHeader,
        'users-list': UsersList,
        'user-form': UserForm,
        's-modal': SModal,
    },
    setup() {
        useTitle('Пользователи')

        const notifications = useNotifications()

        const isUserModalOpened = ref(false)
        const { openModal, closeModal } = useModal(isUserModalOpened)

        const isUpdateUser = ref(false)
        const isCreateUser = ref(true)
        const isUserModalLoading = ref(false)

        const updatingUser: Ref<StaffUserT | null> = ref(null)

        const {
            staffUsers,
            loadStaffUsers,
            loadMoreStaffUsers,
            createUser,
            updateUser,
            deleteUser,
        } = useUsers()

        const loadStaffUsersHandler = async () => {
            try {
                await loadStaffUsers()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка получения пользователей',
                    },
                    error
                )
            }
        }

        onMounted(loadStaffUsersHandler)

        const startUpdateUser = (user: StaffUserT) => {
            isUpdateUser.value = true
            isCreateUser.value = false
            updatingUser.value = user

            openModal()
        }

        const startCreateUser = () => {
            isUpdateUser.value = false
            isCreateUser.value = true

            openModal()
        }

        const createUserHandler = async (user: StaffUserT) => {
            try {
                isUserModalLoading.value = true
                await createUser(user)
                closeModal()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка создания пользователя',
                    },
                    error
                )
            } finally {
                isUserModalLoading.value = false
            }
        }

        const updateUserHandler = async (payload: { user: StaffUserT; user_id: number }) => {
            try {
                isUserModalLoading.value = true
                await updateUser(payload.user_id, payload.user)
                closeModal()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка редактирования пользователя',
                    },
                    error
                )
            } finally {
                isUserModalLoading.value = false
            }
        }

        return {
            isUserModalOpened,
            isUserModalLoading,
            isUpdateUser,
            isCreateUser,
            updatingUser,
            openModal,
            closeModal,
            staffUsers,
            loadStaffUsersHandler,
            loadMoreStaffUsers,
            startUpdateUser,
            startCreateUser,
            createUserHandler,
            updateUserHandler,
            deleteUser,
        }
    },
})
