
import { defineComponent, onMounted, PropType, reactive, toRaw } from 'vue'

import SInput from '@/common/components/SInput/index.vue'
import SPassword from '@/common/components/SPassword/index.vue'
import SCheckbox from '@/common/components/SCheckbox/index.vue'

import SButton from '@/common/components/SButton/index.vue'

import iClose from '@/assets/icons/Close.svg'

import { StaffUserT } from '@/core/types/Users.types'

import { UserValidation } from '@/modules/Users/validations/User.validation'
import useVuelidate from '@vuelidate/core'
import { useMaska } from '@/common/composable/useMaska'

export default defineComponent({
    components: {
        's-button': SButton,
        's-input': SInput,
        's-password': SPassword,
        's-checkbox': SCheckbox,
        'icon-close': iClose,
    },
    props: {
        isUpdate: {
            type: Boolean,
            defaut: false,
        },
        isCreate: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        initialUser: {
            type: Object as PropType<StaffUserT>,
            default: null,
        },
    },
    emits: ['create', 'update', 'close'],
    setup(props, { emit }) {
        const state = reactive({
            name: null as string | null,
            phone: null as string | null,
            email: null as string | null,
            password: null as string | null,
            is_advanced: true,
        })

        const v$ = useVuelidate(UserValidation, state)

        onMounted(() => {
            if (!props.isUpdate || !props.initialUser) {
                v$.value.$reset()
                return
            }

            state.name = props.initialUser.name
            state.phone = props.initialUser.phone
            state.email = props.initialUser.email
            state.is_advanced = props.initialUser.is_advanced

            v$.value.$reset()
        })

        const { unmaskNumbers } = useMaska()

        const submitHandler = () => {
            v$.value.$touch()
            if (v$.value.$invalid) return

            const payload = toRaw({ ...state })
            if (payload.phone) payload.phone = unmaskNumbers(payload.phone)

            if (props.isCreate) {
                emit('create', payload)
                return
            }

            if (props.isUpdate) {
                emit('update', { user: payload, user_id: props.initialUser.id })
                return
            }
        }

        const emitClose = () => {
            emit('close')
        }

        return { state, submitHandler, emitClose, v$ }
    },
})
