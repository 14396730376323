
import { defineComponent, PropType } from 'vue'

import SLoader from '@/common/components/SLoader/index.vue'
import CreateUser from '@/modules/Users/components/CreateUser/index.vue'
import LoadMoreUsers from '@/modules/Users/components/LoadMoreUsers/index.vue'
import UserCard from '@/modules/Users/components/UserCard/index.vue'

import iRefresh from '@/assets/icons/Refresh.svg'

import { StaffUserT } from '@/core/types/Users.types'

export default defineComponent({
    components: {
        's-loader': SLoader,
        'create-user': CreateUser,
        'load-more-users': LoadMoreUsers,
        'user-card': UserCard,
        'icon-refresh': iRefresh,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        failed: {
            type: Boolean,
            default: false,
        },
        users: {
            type: Array as PropType<StaffUserT[]>,
            default: () => [],
        },
        totalPages: {
            type: Number,
            default: 1,
        },
        currentPage: {
            type: Number,
            default: 1,
        },
    },
    emits: ['create', 'update', 'refresh', 'loadmore', 'delete'],
    setup(_, { emit }) {
        const emitCreate = () => {
            emit('create')
        }

        const emitUpdate = (user: StaffUserT) => {
            emit('update', user)
        }

        const emitRefresh = () => {
            emit('refresh')
        }

        const emitLoadmore = () => {
            emit('loadmore')
        }

        const emitDelete = (user_id: number) => {
            emit('delete', user_id)
        }

        return { emitCreate, emitUpdate, emitRefresh, emitLoadmore, emitDelete }
    },
})
