
import { defineComponent, PropType } from 'vue'

import iEdit from '@/assets/icons/Edit.svg'
import iClose from '@/assets/icons/Close.svg'
import { StaffUserT } from '@/core/types/Users.types'

export default defineComponent({
    components: {
        'icon-edit': iEdit,
        'icon-close': iClose,
    },
    props: {
        user: {
            type: Object as PropType<StaffUserT>,
            default: null,
        },
    },
    emits: ['edit', 'delete'],
    setup(props, { emit }) {
        const emitEdit = () => {
            emit('edit', props.user)
        }

        const emitDelete = () => {
            const answer = confirm(`Вы уверены что хотите удалить пользователя ${props.user.name}?`)
            if (!answer) return

            emit('delete', props.user.id)
        }

        return { emitEdit, emitDelete }
    },
})
